import moment from 'moment'
import { mapFields } from 'vuex-map-fields'
import { mapState, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'
import MonthlyPicker from 'vue-monthly-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import PopoverInfo from '@/views/components/popover/PopoverInfo.vue'
import {
  today,
  last7,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
  kompackDate,
} from '@/store/helpers'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { kompackAxiosIns, newAxiosIns } from '@/libs/axios'
import Apexcharts from 'vue-apexcharts'
import { IDR } from '@/libs/currency'
import {
  START_DATE_OF_MONTH, END_DATE_OF_MONTH, LABELDAY, LABELMONTH,
} from '@/libs/filterDate'
import Card from './components/Card.vue'
import ModalWithdrawal from './components/Withdrawal.vue'
import { chartOptions, configColumn } from './tableconfig'
import ModalPin from '../setting/rekeningBank/ModalPin.vue'

export default {
  components: {
    DateRangePicker,
    PopoverInfo,
    vSelect,
    Card,
    ModalWithdrawal,
    Apexcharts,
    ToastificationContent,
    MonthlyPicker,
    ModalPin,
  },
  data() {
    return {
      // Date range picker
      picker: {
        startDate: kompackDate,
        endDate: today,
      },
      isVisibility: true,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Semua waktu': [kompackDate, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      today,
      last7,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      currentStep: 0,
      visibilityPin: 'number',
      pageOptions: [5, 20, 50, 100, 200],
      loading: true,
      balance: {},
      seriesChart: [],
      chartOptions,
      IDR,
      loadingBalance: false,
      loadingPerformance: false,
      totalOrder: 0,
      items: [],
      fields: configColumn,
      filterPerformance: this.$moment(),
      showBalance: false,
    }
  },
  mounted() {
    Promise.all([this.getBalance(), this.getDataPerformance(), this.$store.dispatch('saldo/init')])
      .then(() => {})
      .catch(err => {
        this.alertError()
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapFields('saldo', ['dateRange', 'saldo', 'saldoPending']),
    ...mapState('saldo', ['table', 'riwayatPenarikans']),
    ...mapGetters('saldo', ['rekenings', 'rekening', 'rekTujuanOptions']),
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
  },
  watch: {
    'table.currentPage': {
      handler() {
        this.$store.dispatch('saldo/getWithdrawalRequest')
      },
    },
    'table.perPage': {
      handler() {
        this.$store.dispatch('saldo/getWithdrawalRequest')
      },
      deep: true,
    },
    dateRange: {
      handler() {
        this.$store.commit('saldo/UPDATE_CURRENT_PAGE', 1)
        this.$store.dispatch('saldo/getWithdrawalRequest')
      },
      deep: true,
    },
    filterPerformance: {
      handler() {
        this.getDataPerformance()
      },
    },
  },
  beforeMount() {
    this.$store.commit('saldo/RESET_STORE')
  },
  methods: {
    async getBalance() {
      this.loadingBalance = true
      const url = '/v1/kompack/dashboard/balance'
      await kompackAxiosIns.get(url).then(res => {
        const { data } = res.data
        this.balance = data
        this.loadingBalance = false
        this.saldo = data.balance
        this.saldoPending = data.pending_comission
      })
    },
    getDataPerformance() {
      this.loadingPerformance = true
      const params = `start_date=${START_DATE_OF_MONTH(
        this.filterPerformance,
      )}&end_date=${END_DATE_OF_MONTH(this.filterPerformance)}`
      const url = `kompack/api/v1/dashboard/mitra/performance-summary?${params}`
      newAxiosIns.get(url).then(res => {
        const { data } = res.data
        this.loadingPerformance = false
        this.totalOrder = data.total_order
        this.items = data.partner_performance
        this.seriesChart = [
          {
            name: 'JNE',
            data: data.packing_performance.map(packing => packing.total_order),
          },
        ]
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: data.packing_performance.map(packing => moment(packing.date).format('DD')),
          },
        }
      })
    },
    alertError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text:
            'Unable to get the data. Please try again later or contact support.',
          variant: 'danger',
        },
      })
    },
    showTopUpModal() {
      this.$bvModal.show('modalTopUp')
      this.changeAttr()
    },
    formatNumber(n) {
      return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    showModal() {
      this.resetModal()
      this.$bvModal.show('modal-keuangan')
      this.changeAttr()
    },
    async changeAttr() {
      const element = document.getElementsByTagName('body')[0].className

      await (element === 'modal-open')
      document
        .querySelectorAll('div.modal-content')[0]
        .removeAttribute('tabindex')
    },
    closeModal() {
      this.$bvModal.hide('modalTopUp')
      this.$bvModal.hide('modal-keuangan')
    },
    resetModal() {
      this.stepNow = 0
      this.modalTitle = 'Penarikan Saldo'
      this.nominal = ''
      this.nominalState = null
      this.pin = ''
      this.pinState = null
      this.selectedRekTujuan = null
    },
    modalBack() {
      const nominalBefore = this.nominal
      const rekTujuanBefore = this.selectedRekTujuan
      this.resetModal()
      this.nominal = nominalBefore
      this.selectedRekTujuan = rekTujuanBefore
    },
    async handleSubmit(step) {
      if (!this.checkFormValidity(step)) {
        return
      }

      switch (step) {
        case 1:
          this.$nextTick(() => {
            this.stepNow = 1
            this.modalTitle = 'Verifikasi PIN'
            const mediaQuery = window.matchMedia('(max-width: 768px)')
            if (mediaQuery.matches) {
              this.fieldwidth = '40'
              this.fieldheight = '44'
            } else {
              this.fieldwidth = '50'
              this.fieldheight = '54'
            }
          })
          break
        case 2:
          try {
            const response = await this.$store.dispatch('saldo/checkPin')
            const responseReq = this.$store.dispatch('saldo/withdrawalRequest')
            if (!response.data.data.is_match) {
              throw { message: 'Maaf pin yang anda masukkan salah' } // eslint-disable-line
            }
            responseReq
              .then(val => {
                const { data } = val
                this.resTarikSaldo = data.data
                this.$nextTick(() => {
                  this.stepNow = 2
                  this.modalTitle = null
                  this.status = data.status
                })
              })
              .catch(e => {
                if (e.response.status === 400) {
                  this.$swal({
                    title:
                      '<span class="font-weight-bold h4">Penarikan Saldo Gagal</span>',
                    text:
                      'Maaf, kamu tidak bisa melakukan penarikan saldo dikarenakan kamu masih memiliki antrian penarikan yang belum disetujui.',
                    imageUrl: require('@/assets/images/icons/fail.svg'), // eslint-disable-line
                    showCloseButton: false,
                    focusConfirm: true,
                    confirmButtonText: 'Lihat riwayat penarikan',
                    customClass: {
                      confirmButton: 'btn bg-orange2 btn-primary rounded-lg',
                      popup: 'mr-2 ml-1',
                    },
                    buttonsStyling: false,
                  }).then(res => {
                    if (res.isConfirmed) {
                      this.$router.push('keuangan/saldo')
                    }
                  })
                }
              })

            this.visibilityPin = 'number'
          } catch (e) {
            this.$swal({
              title:
                '<span class="font-weight-bold h4">Penarikan Saldo Gagal</span>',
              text: e.message,
              imageUrl: require('@/assets/images/icons/fail.svg'), // eslint-disable-line
              showCloseButton: false,
              focusConfirm: true,
              confirmButtonText: 'Oke',
              customClass: {
                confirmButton: 'btn bg-orange2 btn-primary rounded-lg',
                popup: 'mr-2 ml-1',
              },
              buttonsStyling: false,
            })
          }
          break
        default:
          break
      }
    },
    formatCurrency(blur, el) {
      const input = document.getElementById(el)
      let inputVal = input.value

      if (inputVal === '' || inputVal === 'Rp' || inputVal === 'Rp ') {
        return
      }

      const originalLen = inputVal.length
      let caretPos = input.selectionStart

      if (inputVal.indexOf(',') >= 0) {
        const decimalPos = inputVal.indexOf(',')
        let leftSide = inputVal.substring(0, decimalPos)
        let rightSide = inputVal.substring(decimalPos)

        leftSide = this.formatNumber(leftSide)
        rightSide = this.formatNumber(rightSide)

        if (blur) {
          rightSide += '00'
        }
        rightSide = rightSide.substring(0, 2)
        inputVal = 'Rp ' + leftSide + ',' + rightSide // eslint-disable-line
      } else {
        inputVal = this.formatNumber(inputVal)
        inputVal = 'Rp ' + inputVal // eslint-disable-line

        if (blur) {
          inputVal += ',00'
        }
      }

      input.value = inputVal

      const updatedLen = inputVal.length
      caretPos = updatedLen - originalLen + caretPos
      input.setSelectionRange(caretPos, caretPos)
    },
    stopCarret() {
      const obj = document.getElementById('input2')
      if (obj.value.length > 5) {
        this.setCaretPosition(obj, 0)
      }
    },
    setCaretPosition(elem, caretPos) {
      if (elem != null) {
        if (elem.createTextRange) {
          const range = elem.createTextRange()
          range.move('character', caretPos)
          range.select()
        } else if (elem.selectionStart) {
          elem.focus()
          elem.setSelectionRange(caretPos, caretPos)
        } else {
          elem.focus()
        }
      }
    },
    alertFail() {
      this.$swal({
        title:
          '<span class="font-weight-bold h4">Maaf sedang ada gangguan,<br>coba lagi nanti</span>',
        imageUrl: require('@/assets/images/icons/fail.svg'), // eslint-disable-line
        showCloseButton: false,
        focusConfirm: true,
        confirmButtonText: 'Oke',
        customClass: {
          confirmButton: 'btn bg-orange2 btn-primary rounded-lg',
          popup: 'mr-2 ml-1',
        },
        buttonsStyling: false,
      })
    },
    toggleVisibilityPin() {
      this.isVisibility = !this.isVisibility
    },
    onChange(v) {
      this.pin = v
    },
    onComplete(v) {
      this.pin = v
    },
    openDetail(userId, id) {
      this.$store.commit('withdrawDetail/UPDATE_USER_ID', userId)
      this.$router.push(`/saldo/detail-penarikan/${id}`)
    },
    setPage(perPage) {
      this.$store.commit('saldo/UPDATE_PER_PAGE', perPage)
    },
    checkPossibilityWD() {
      this.$store
        .dispatch('saldo/checkPossibilityWD')
        .then(res => {
          this.$bvModal.show('modal-keuangan')
        })
        .catch(err => {
          this.alertError()
        })
    },
    onSubmitPin() {
      sessionStorage.setItem('showBalance', 'true')
      this.showBalance = true
    },
    onEyeClick() {
      if (!sessionStorage.getItem('showBalance')) {
        this.$bvModal.show('modal-pin')
      } else {
        this.showBalance = !this.showBalance
      }
    },
  },
}
