const informasiSaldo = [
  {
    key: 'date',
    label: 'Tanggal',
    thClass: 'text-capitalize h-text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'withdrawal_destination',
    label: 'Tujuan Penarikan',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'status',
    label: 'Status',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'amount',
    label: 'Jumlah Penarikan',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'detail',
    label: 'Rincian',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
]

const detailSaldo = [
  {
    key: 'date',
    label: 'Tanggal',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'jenis_transaksi',
    label: 'Jenis Transaksi',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'jumlah',
    label: 'Jumlah',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'saldo',
    label: 'Saldo',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'detail',
    label: 'Rincian',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
]

const detailWithdrawal = [
  {
    key: 'date',
    label: 'Tanggal',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'jenis_transaksi',
    label: 'Jenis Transaksi',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'jumlah',
    label: 'Jumlah',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'saldo',
    label: 'Saldo',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'detail',
    label: 'Rincian',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
]

const outboundCosts = [
  {
    key: 'tanggal_pickup',
    label: 'Tanggal Pengajuan Pickup',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'tanggal_order',
    label: 'Tanggal Order',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'produk',
    label: 'Produk',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'no_resi',
    label: 'No Resi',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
  {
    key: 'biaya_fulfillment',
    label: 'Biaya Fulfillment',
    thClass: 'text-capitalize text-dark text-2 text-left',
    tdClass: 'text-left',
  },
]
const colorDefaultChart = ['#FBA63C']

const chartOptions = {
  colors: colorDefaultChart,
  chart: {
    type: 'area',
    stacked: false,
    height: 350,
    width: '100%',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  stroke: {
    curve: 'smooth',
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.75,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  yaxis: {
    forceNiceScale: true,
    axisBorder: {
      show: false,
    },
    labels: {
      style: {
        colors: '#000000',
      },
      offsetX: 0,
      formatter: val => val,
    },
    axisTicks: {
      show: false,
    },
  },
  xaxis: {
    type: 'category',
    categories: [],
    tickAmount: 14,
  },
  noData: {
    text: 'Tidak ada data',
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
  },
  tooltip: {
    shared: false,
    custom({ dataPointIndex, w }) {
      const arrayData = [...w.globals.series]
      let htmlRender = ''
      arrayData.forEach((data, idx) => {
        const seriesValue = data[dataPointIndex]
        htmlRender += `<div><span style="color: #222222"> Total Order: ${seriesValue}</span></div>`
      })

      return `<div class="px-1 py-75" style="box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);">${htmlRender}</div>`
    },
  },
}

const configColumn = [
  {
    key: 'id',
    label: 'No',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      background: '#FCEBBE',
    },
    tdClass: 'text-black',
  },
  {
    key: 'brand_name',
    label: 'Nama',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      background: '#FCEBBE',
    },
    tdClass: 'text-black',
  },
  {
    key: 'total_order',
    label: 'Jumlah Order',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '13px',
      color: 'black',
      background: '#FCEBBE',
    },
    tdClass: 'text-black',
    class: 'text-center',
  },
]

export {
  informasiSaldo,
  detailSaldo,
  detailWithdrawal,
  outboundCosts,
  chartOptions,
  configColumn,
}
