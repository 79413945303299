<template>
  <div class="bg-white custom-card d-flex flex-column justify-content-start gap-20 w-100 shadow">
    <div :class="source !== 'packing' ? 'title d-flex gap-1 items-center text-xl' : 'title d-flex gap-1 items-center text-xl justify-between'">
      {{ cardTitle }}
      <span
        v-if="info"
        v-b-popover.hover.top="info"
      >
        <img
          src="@/assets/images/icons/info-circle.svg"
          alt="Info"
        >
      </span>
      <b-button
        v-if="source === 'packing'"
        variant="outline-secondary"
        size="sm"
        class="mr-1 rounded-lg p-0"
        @click="$router.push('/packing')"
      >
        <feather-icon
          size="2x"
          icon="ChevronRightIcon"
        />
      </b-button>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    cardTitle: String,
    info: String,
    source: String,
  },
}
</script>
